<template>
  <div>
    <b-nav class="d-md-inline-flex d-none">
      <b-nav-item
        v-for="optionM in optionsMenu"
        :key="optionM.id"
        :disabled="optionM.disabled"
        :class="checkPermission([optionM.key]) ? 'text-center': 'd-none text-center'"
        @click="applyRedirect(optionM)"
      >
        <template>
          <div :class="optionM.status ? 'i-nav-active' : ''">
            <i :class="`${optionM.icon} i-size-icon`" />
            <span>
              {{ $t(optionM.name) }}
            </span>
          </div>
        </template>
      </b-nav-item>
    </b-nav>
    <div class="i-show-drop">
      <div class="i-dropdown">
        <button class="i-drop-button">
          <i :class="`${iconActive} i-size-icon`" />
          <span style="margin-left: 3px">
            <feather-icon
              icon="ChevronDownIcon"
              size="16"
              class="rotates"
            />
          </span>
        </button>
        <div class="i-dropdown-content i-cl-exception">
          <div
            v-for="optionM in optionsMenu"
            :key="optionM.id"
            :class="checkPermission([optionM.key]) ? optionM.status ? 'i-nav-active' : '' : 'd-none'"
            @click="applyRedirect(optionM)"
          >
            <template v-if="checkPermission([optionM.key])">
              <i :class="`${optionM.icon} i-size-icon`" />
              {{ $t(optionM.name) }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BNav, BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNav,
    BNavItem,
  },

  props: {
    optionsMenu: {
      required: true,
      type: Array,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      optionActive: '',
      iconActive: '',
    }
  },

  watch: {
    '$route.name': function () {
      this.loadRouteData()
    },
  },

  mounted() {
    this.loadRouteData()
  },

  methods: {
    loadRouteData() {
      const routeData = this.optionsMenu.filter(item => item.status)
      this.optionActive = routeData[0].name
      this.iconActive = routeData[0].icon
    },

    applyRedirect(opt) {
      if (this.$route.name !== opt.route.name) {
        this.optionActive = opt.name
        this.iconActive = opt.icon
        this.$router.push({ name: opt.route.name })
      }
    },
  },
}
</script>
